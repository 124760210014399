import { useState, useEffect, useContext, createRef } from "react"
import IconHome from "../../../../assets/img/home_grey.png"
import IconApple from "../../../../assets/img/diet_grey.png"
import IconBrain from "../../../../assets/img/ps_grey.png"
import IconHomeBlack from "../../../../assets/img/home_black.png"
import IconAppleBlack from "../../../../assets/img/diet_black.png"
import IconBrainBlack from "../../../../assets/img/ps_black.png"
import IconChevron from "../../../../assets/img/chevron-down.png"
import optionMarker from "../../../../assets/img/option_marker.png"
import "./SideBar.css"

import HomePrivate from "../homeprivate/HomePrivate"
import YourDiet from "../diet/your-diet/YourDiet"
import YourTraining from "../diet/your-training/YourTraining"
import YourTransformation from "../diet/your-transformation/YourTransformation"
import VideoCall from "../diet/video-call/VideoCall"
import ContactSpecialist from "../diet/contact-specialist/Contact-Specialist"
import Calendar from "../diet/calendar/calendar"
import ScheduleSession from "../psychology/schedule-session/ScheduleSession"
import ScheduleSessionCouple from "../psychology-couple/schedule-session/ScheduleSession"
import ScheduleSuscription from "../psychology-subscription/schedule-session/ScheduleSuscription"
import ScheduleSessionDieMesPsi from "../psychology-die-mes-psi/schedule-session/ScheduleDieMesPsi"
import ContactSpecialistPsychology from "../psychology/contact-specialist/ContactSpecialistPsychology"
import ContactSpecialistPsychologyCouple from "../psychology-couple/contact-specialist-psychology-couple/ContactSpecialistPsychology"
import ContactSpecialistPsychologySuscription from "../psychology-subscription/contact-specialst/ContactSpecialistPsychologySuscription"
import ContactSpecialistPsychologyDieMesPsi from "../psychology-die-mes-psi/contact-specialst/ContactSpecialistPsychology"
import MediaQuery from "react-responsive"
import PlansSubscriptions from "../../../views/plans-subscriptions/plans-subscriptions"
import ReloadContext from "../../../../providers/ReloadProvider"
import MenuContext from "../../../../providers/MenuProvider"
import Data from "../../profile/data/Data"
import PlansRecord from "../../profile/plansRecord/PlansRecord"
import { getIsGeopolPlan, getLastDietPlan } from "../../../../utils/dietPlanStorage"

const Sidebar: React.FC = () => {
  const { reload, setReload } = useContext(ReloadContext)
  const [menuType, setMenuType] = useState<string | null>(null)
  const [psychologyExpanded, setPsychologyExpanded] = useState<boolean | null>(null)
  const [psychologyCoupleExpanded, setPsychologyCoupleExpanded] = useState<boolean | null>(null)
  const [psychologySuscriptionExpanded, setPsychologySuscriptionExpanded] = useState<
    boolean | null
  >(false)
  const [psychologyDietPsiExpanded, setPsychologyDietPsiExpanded] = useState<boolean | null>(false)
  const [dietExpanded, setDietExpanded] = useState<boolean | null>(null)
  const [selectedOption, setSelectedOption] = useState<string | null>(null)
  const [showMenu, setShowMenu] = useState(false)
  const [chargeSidebar, setChargeSidebar] = useState(false)
  const { showMenuPrincipal, setShowMenuPrincipal } = useContext(MenuContext)
  const [isVwDiet, setIsVwDiet] = useState<boolean | null>(null)
  const [dietPlan, setDietPlan] = useState(false)
  const [psychoPlan, setPsychoPlan] = useState(false)
  const [psychoPlanCouple, setPsychoPlanCouple] = useState(false)
  const [psychoPlanSuscription, setPsychoPlanSuscription] = useState(false)
  const [psychoPlanDietPsi, setPsychoPlanDietPsi] = useState(false)
  const [isGeopolPlan, setIsGeopolPlan] = useState(false)

  const refSide = createRef<HTMLDivElement>()

  useEffect(() => {
    const lastDietPlan = getLastDietPlan()
    if (lastDietPlan) setIsGeopolPlan(getIsGeopolPlan(lastDietPlan))

    const pon_user_info_diet = localStorage.getItem("pon_user_info_diet")
    if (pon_user_info_diet && JSON.parse(pon_user_info_diet) !== null) {
      setDietPlan(true)
    }
    const typePlanArray = pon_user_info_diet !== null ? JSON.parse(pon_user_info_diet) : null

    const lastTypePlan =
      typePlanArray !== null ? typePlanArray[typePlanArray.length - 1].typePlan : ""

    if (lastTypePlan == "die_mes_psi") {
      setIsVwDiet(true)
    }

    const pon_user_info_psi = localStorage.getItem("pon_user_info_psi")
    if (pon_user_info_psi && JSON.parse(pon_user_info_psi) !== null) {
      setPsychoPlan(true)
    }

    const pon_user_info_psi_couple = localStorage.getItem("pon_user_info_psi_couple")
    if (pon_user_info_psi_couple && JSON.parse(pon_user_info_psi_couple) !== null) {
      setPsychoPlanCouple(true)
    }
    const pon_user_info_psi_suscription = localStorage.getItem("pon_user_info_psi_suscription")
    if (pon_user_info_psi_suscription && JSON.parse(pon_user_info_psi_suscription) !== null) {
      setPsychoPlanSuscription(true)
    }

    const pon_user_info_psi_die_mes_psi = localStorage.getItem("pon_user_info_psi_die_mes_psi")
    if (pon_user_info_psi_die_mes_psi && JSON.parse(pon_user_info_psi_die_mes_psi) !== null) {
      setPsychoPlanDietPsi(true)
    }
  }, [chargeSidebar])

  const [urlToOpen, setUrlToOpen] = useState("")

  const takeSnapshot = (
    menu: string,
    selectedOption: string,
    psychologyExpanded: boolean,
    dietExpanded: boolean
  ) => {
    let status = {
      menu,
      selectedOption,
      psychologyExpanded,
      dietExpanded
    }
    localStorage.setItem("privateSelectedView", JSON.stringify(status))
  }

  const getSnapshot = () => {
    let status = localStorage.getItem("privateSelectedView")
    if (status === undefined || status === null) {
      return {
        menu: "sidebar",
        selectedOption: "Home",
        psychologyExpanded: false,
        dietExpanded: false
      }
    }
    return JSON.parse(status)
  }

  useEffect(() => {
    let status = getSnapshot()
    setMenuType(status.menu)
    setSelectedOption(status.selectedOption)
    setDietExpanded(status.dietExpanded)
    setPsychologyExpanded(status.psychologyExpanded)
  }, [])

  useEffect(() => {
    if (selectedOption !== null && psychologyExpanded !== null && dietExpanded !== null) {
      takeSnapshot("sidebar", selectedOption, psychologyExpanded, dietExpanded)
    }
  }, [selectedOption, psychologyExpanded, dietExpanded])

  useEffect(() => {
    if (reload) {
      let status = getSnapshot()
      setMenuType(status.menu)
      setSelectedOption(status.selectedOption)
      setDietExpanded(status.dietExpanded)
      setPsychologyExpanded(status.psychologyExpanded)
      setReload(false)
    }
  }, [reload, setReload])

  const handleOptionClick = (option: string) => {
    const encodedEmail = localStorage.getItem("encodedEmail")
    const encodedPassword = localStorage.getItem("encodedPassword")
    let url = ""

    switch (option) {
      case "Home":
        break
      case "Your Diet":
        setMenuType("sidebar")
        setSelectedOption("Your Diet")
        setDietExpanded(true)
        setPsychologyExpanded(false)
        setReload(false)

        break
      case "Your Training":
        break
      case "Your Transformation":
        break
      case "Video Call":
        break
      case "Contact Specialist":
        break
      case "Calendar":
        break
      case "Schedule Session":
        setMenuType("sidebar")
        setSelectedOption("Schedule Session")
        setDietExpanded(false)
        setPsychologyExpanded(true)
        setReload(false)
        break
      case "Contact Specialist Psychology":
        break
      case "Schedule Session Couple":
        setMenuType("sidebar")
        setSelectedOption("Schedule Session Couple")
        setDietExpanded(false)
        setPsychologyExpanded(false)
        setPsychologyCoupleExpanded(true)
        setReload(false)

        break
    }
    setShowMenu(false)
    setSelectedOption(option)
    if (url !== "") {
      setUrlToOpen(
        `${process.env.REACT_APP_FRONT_URL}/gateway.html?param1=${encodedEmail}&param2=${encodedPassword}&param3=${url}`
      )
    }
  }

  useEffect(() => {
    console.log(psychologyCoupleExpanded)
  }, [psychologySuscriptionExpanded])

  const renderContent = () => {
    switch (selectedOption) {
      case "Home":
        return (
          <HomePrivate
            handleOptionClick={handleOptionClick}
            chargeSidebar={chargeSidebar}
            setChargeSidebar={setChargeSidebar}
          />
        )
      case "Your Diet":
        return <YourDiet isGeopolPlan={isGeopolPlan} handleOptionClick={handleOptionClick} />
      case "Your Training":
        return <YourTraining handleOptionClick={handleOptionClick} />
      case "Your Transformation":
        return <YourTransformation />
      case "Video Call":
        return <VideoCall url={urlToOpen} />
      case "Contact Specialist":
        return <ContactSpecialist />
      case "Calendar":
        return (
          <Calendar tab={"dates"} miComponenteRef={refSide} handleOptionClick={handleOptionClick} />
        )
      case "Calendar Videocall":
        return (
          <Calendar
            tab={"videocall"}
            miComponenteRef={refSide}
            handleOptionClick={handleOptionClick}
          />
        )
      case "Schedule Session":
        return <ScheduleSession tab={"dates"} />
      case "Schedule Session Videocall":
        return <ScheduleSession tab={"videocall"} />
      case "Schedule Session Couple":
        return <ScheduleSessionCouple tab={"no-vc"} />
      case "Schedule Session Suscription":
        return <ScheduleSuscription tab={"dates"} />
      case "Schedule Session Suscription Dates":
        return <ScheduleSuscription tab={"videocall"} />
      case "Schedule Session DietMesPsi":
        return <ScheduleSessionDieMesPsi tab={"dates"} />
      case "Contact Specialist Psychology":
        return <ContactSpecialistPsychology />
      case "Contact Specialist Psychology Suscription":
        return <ContactSpecialistPsychologySuscription />
      case "Contact Specialist Psychology DietMesPsi":
        return <ContactSpecialistPsychologyDieMesPsi />
      case "Contact Specialist Psychology Couple":
        return <ContactSpecialistPsychologyCouple />
      case "Buys and Subscriptions":
        return <PlansSubscriptions />
      case "Session and Security":
        return <Data />
      case "Plans Record":
        return <PlansRecord />
      default:
        return null
    }
  }

  return (
    <>
      <div ref={refSide}></div>
      <MediaQuery maxWidth={768}>
        <button
          className="menu-toggle"
          onClick={() => {
            setShowMenuPrincipal(false)
            setShowMenu(!showMenu)
          }}
          style={{ position: "fixed", top: "0.7rem", zIndex: 99999, left: "0.6rem" }}
        >
          <span className="menu-icon">
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </span>
        </button>
      </MediaQuery>
      {showMenu && showMenuPrincipal === false && (
        <div className="options-container-mobile">
          <div className="sidebar-menu">
            <div
              className={`option${selectedOption === "Home" ? " selected" : " white-option"}`}
              onClick={() => handleOptionClick("Home")}
            >
              <div className="sidebar-menu-options">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "75%"
                  }}
                >
                  {selectedOption === "Home" ? (
                    <img src={IconHomeBlack} className="sidebar-icon" alt="Home" />
                  ) : (
                    <img src={IconHome} className="sidebar-icon" alt="Home" />
                  )}
                  <p onClick={() => setShowMenu(false)} className={"hidden"}>
                    Home
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "25%",
                    justifyContent: "flex-end",
                    alignItems: "center"
                  }}
                >
                  <img
                    src={optionMarker}
                    style={{
                      marginRight: 0,
                      display: selectedOption === "Home" ? "block" : "none"
                    }}
                    className={"option_marker"}
                  />
                </div>
              </div>
            </div>
            {dietPlan && (
              <div
                className={`option option_header${
                  dietExpanded && selectedOption !== "Home" ? " selected" : " white-option"
                }`}
                onClick={() => setDietExpanded(!dietExpanded)}
              >
                <div className="sidebar-menu-options">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%"
                    }}
                  >
                    {dietExpanded && selectedOption !== "Home" ? (
                      <img src={IconAppleBlack} className="sidebar-icon" alt="plan dieta" />
                    ) : (
                      <img src={IconApple} className="sidebar-icon" alt="plan dieta" />
                    )}
                    <p className={"hidden"} style={{ width: "65%" }}>
                      {isVwDiet ? "V.Wellness Dieta" : "Dieta"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: dietExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility: dietExpanded && selectedOption !== "Home" ? "visible" : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {dietExpanded && (
              <div>
                <div
                  className={`option${selectedOption === "Your Diet" ? " selected" : ""}`}
                  onClick={() => handleOptionClick("Your Diet")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      {isGeopolPlan ? "Tu guía nutricional" : "Tu dieta"}
                    </p>
                  </div>
                </div>
                {!isGeopolPlan && (
                  <div
                    className={`option${selectedOption === "Your Training" ? " selected" : ""}`}
                    onClick={() => handleOptionClick("Your Training")}
                  >
                    <div className="sidebar-menu-options">
                      <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                        Tu entrenamiento
                      </p>
                    </div>
                  </div>
                )}
                {!isGeopolPlan && (
                  <div
                    className={`option${
                      selectedOption === "Your Transformation" ? " selected" : ""
                    }`}
                    onClick={() => handleOptionClick("Your Transformation")}
                  >
                    <div className="sidebar-menu-options">
                      <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                        Tu transformación
                      </p>
                    </div>
                  </div>
                )}

                <div
                  className={`option${selectedOption === "Calendar" ? " selected" : ""}`}
                  onClick={() => handleOptionClick("Calendar")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Calendario
                    </p>
                  </div>
                </div>
                {!isGeopolPlan && (
                  <div
                    className={`option${
                      selectedOption === "Contact Specialist" ? " selected" : ""
                    }`}
                    onClick={() => handleOptionClick("Contact Specialist")}
                  >
                    <div className="sidebar-menu-options">
                      <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                        Contacta con tu especialista
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {psychoPlan && (
              <div
                className={`option option_header${
                  psychologyExpanded && selectedOption !== "Home" ? " selected" : " white-option"
                }`}
                onClick={() => setPsychologyExpanded(!psychologyExpanded)}
              >
                <div className="sidebar-menu-options">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%"
                    }}
                  >
                    {psychologyExpanded && selectedOption !== "Home" ? (
                      <img src={IconBrainBlack} className="sidebar-icon" alt="plan psicología" />
                    ) : (
                      <img src={IconBrain} className="sidebar-icon" alt="plan psicología" />
                    )}
                    <p className={"hidden"} style={{ width: "60%" }}>
                      Bonos Psicología
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: psychologyExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility:
                          psychologyExpanded && selectedOption !== "Home" ? "visible" : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {psychologyExpanded && (
              <div>
                <div
                  className={`option${selectedOption === "Schedule Session" ? " selected" : ""}`}
                  onClick={() => handleOptionClick("Schedule Session")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Calendario y gestion de videollamada{" "}
                    </p>
                  </div>
                </div>
                <div
                  className={`option${
                    selectedOption === "Contact Specialist Psychology" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Contact Specialist Psychology")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Contacta con tu especialista
                    </p>
                  </div>
                </div>
              </div>
            )}

            {psychoPlanCouple && (
              <div
                className={`option option_header${
                  psychologyCoupleExpanded && selectedOption !== "Home"
                    ? " selected"
                    : " white-option"
                }`}
                onClick={() => setPsychologyCoupleExpanded(!psychologyCoupleExpanded)}
              >
                <div className="sidebar-menu-options sidebar-psi-couple">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "90%",
                      paddingTop: "1rem",
                      paddingBottom: "1rem"
                    }}
                  >
                    {psychologyCoupleExpanded && selectedOption !== "Home" ? (
                      <img
                        src={IconBrainBlack}
                        className="sidebar-icon"
                        alt="plan psicología pareja"
                      />
                    ) : (
                      <img src={IconBrain} className="sidebar-icon" alt="plan psicología pareja" />
                    )}
                    <p className={"hidden couple-burger-title"} style={{ width: "100%" }}>
                      Bonos Psicología parejas
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: psychologyCoupleExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility:
                          psychologyCoupleExpanded && selectedOption !== "Home"
                            ? "visible"
                            : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {psychologyCoupleExpanded && (
              <div>
                <div
                  className={`option${
                    selectedOption === "Schedule Session Couple" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Schedule Session Couple")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Calendario y gestion de videollamada{" "}
                    </p>
                  </div>
                </div>
                <div
                  className={`option${
                    selectedOption === "Contact Specialist Psychology Couple" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Contact Specialist Psychology Couple")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Contacta con tu especialista
                    </p>
                  </div>
                </div>
              </div>
            )}
            {psychoPlanSuscription && (
              <div
                className={`option option_header${
                  psychologySuscriptionExpanded && selectedOption !== "Home"
                    ? " selected"
                    : " white-option"
                }`}
                onClick={() => setPsychologySuscriptionExpanded(!psychologySuscriptionExpanded)}
              >
                <div className="sidebar-menu-options">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%"
                    }}
                  >
                    {psychologySuscriptionExpanded && selectedOption !== "Home" ? (
                      <img src={IconBrainBlack} className="sidebar-icon" alt="plan psicología" />
                    ) : (
                      <img src={IconBrain} className="sidebar-icon" alt="plan psicología" />
                    )}
                    <p className={"hidden"} style={{ width: "090%" }}>
                      Suscripción Psicología
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: psychologyExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility:
                          psychologyExpanded && selectedOption !== "Home" ? "visible" : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {psychologySuscriptionExpanded && (
              <div>
                <div
                  className={`option${
                    selectedOption === "Schedule Session Suscription" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Schedule Session Suscription")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Calendario y gestion de videollamada{" "}
                    </p>
                  </div>
                </div>
                <div
                  className={`option${
                    selectedOption === "Contact Specialist Psychology Suscription"
                      ? " selected"
                      : ""
                  }`}
                  onClick={() => handleOptionClick("Contact Specialist Psychology Suscription")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Contacta con tu especialista
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
            {psychoPlanDietPsi && (
              <div
                className={`option option_header${
                  psychologyDietPsiExpanded && selectedOption !== "Home"
                    ? " selected"
                    : " white-option"
                }`}
                onClick={() => setPsychologyDietPsiExpanded(!psychologyDietPsiExpanded)}
              >
                <div className="sidebar-menu-options">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%"
                    }}
                  >
                    {psychologyDietPsiExpanded && selectedOption !== "Home" ? (
                      <img src={IconBrainBlack} className="sidebar-icon" alt="plan psicología" />
                    ) : (
                      <img src={IconBrain} className="sidebar-icon" alt="plan psicología" />
                    )}
                    <p className={"hidden"} style={{ width: "090%" }}>
                      V.Wellness psicología
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: psychologyExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility:
                          psychologyExpanded && selectedOption !== "Home" ? "visible" : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {psychologyDietPsiExpanded && (
              <div>
                <div
                  className={`option${
                    selectedOption === "Schedule Session DietMesPsi" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Schedule Session DietMesPsi")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Calendario y gestion de videollamada{" "}
                    </p>
                  </div>
                </div>
                <div
                  className={`option${
                    selectedOption === "Contact Specialist Psychology DietMesPsi" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Contact Specialist Psychology DietMesPsi")}
                >
                  <div className="sidebar-menu-options">
                    <p onClick={() => setShowMenu(false)} style={{ paddingLeft: "50px" }}>
                      Contacta con tu especialista
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}
          </div>
        </div>
      )}

      <div className="sidebar-container">
        <div className="options-container">
          <div className="sidebar-menu">
            <div
              className={`option${selectedOption === "Home" ? " selected" : " white-option"}`}
              onClick={() => handleOptionClick("Home")}
            >
              <div className="sidebar-menu-options">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "75%"
                  }}
                >
                  {selectedOption === "Home" ? (
                    <img src={IconHomeBlack} className="sidebar-icon" alt="Home" />
                  ) : (
                    <img src={IconHome} className="sidebar-icon" alt="Home" />
                  )}
                  <p className={"hidden"}>Home</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "25%",
                    justifyContent: "flex-end",
                    alignItems: "center"
                  }}
                >
                  <img
                    src={optionMarker}
                    style={{
                      marginRight: 0,
                      display: selectedOption === "Home" ? "block" : "none"
                    }}
                    className={"option_marker"}
                  />
                </div>
              </div>
            </div>
            {dietPlan && (
              <div
                className={`option option_header${
                  dietExpanded && selectedOption !== "Home" ? " selected" : " white-option"
                }`}
                onClick={() => setDietExpanded(!dietExpanded)}
              >
                <div className="sidebar-menu-options">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%"
                    }}
                  >
                    {dietExpanded && selectedOption !== "Home" ? (
                      <img src={IconAppleBlack} className="sidebar-icon" alt="plan dieta" />
                    ) : (
                      <img src={IconApple} className="sidebar-icon" alt="plan dieta" />
                    )}
                    <p className={"hidden"} style={{ width: "65%" }}>
                      {isVwDiet ? "V.Wellness Dieta" : "Dieta"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: dietExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility: dietExpanded && selectedOption !== "Home" ? "visible" : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {dietExpanded && (
              <div>
                <div
                  className={`option${selectedOption === "Your Diet" ? " selected" : ""}`}
                  onClick={() => handleOptionClick("Your Diet")}
                >
                  <div className="sidebar-menu-options">
                    <p style={{ paddingLeft: "50px" }}>
                      {isGeopolPlan ? "Tu guía nutricional" : "Tu dieta"}
                    </p>
                  </div>
                </div>
                {!isGeopolPlan && (
                  <div
                    className={`option${selectedOption === "Your Training" ? " selected" : ""}`}
                    onClick={() => handleOptionClick("Your Training")}
                  >
                    <div className="sidebar-menu-options">
                      <p style={{ paddingLeft: "50px" }}>Tu entrenamiento</p>
                    </div>
                  </div>
                )}
                {!isGeopolPlan && (
                  <div
                    className={`option${
                      selectedOption === "Your Transformation" ? " selected" : ""
                    }`}
                    onClick={() => handleOptionClick("Your Transformation")}
                  >
                    <div className="sidebar-menu-options">
                      <p style={{ paddingLeft: "50px" }}>Tu transformación</p>
                    </div>
                  </div>
                )}
                <div
                  className={`option${selectedOption === "Calendar" ? " selected" : ""}`}
                  onClick={() => handleOptionClick("Calendar")}
                >
                  <div className="sidebar-menu-options">
                    <p style={{ paddingLeft: "50px" }}>Calendario y gestión de videollamada </p>
                  </div>
                </div>
                {!isGeopolPlan && (
                  <div
                    className={`option${
                      selectedOption === "Contact Specialist" ? " selected" : ""
                    }`}
                    onClick={() => handleOptionClick("Contact Specialist")}
                  >
                    <div className="sidebar-menu-options">
                      <p style={{ paddingLeft: "50px" }}>Contacta con tu especialista</p>
                    </div>
                  </div>
                )}
              </div>
            )}
            {psychoPlan && (
              <div
                className={`option option_header${
                  psychologyExpanded && selectedOption !== "Home" ? " selected" : " white-option"
                }`}
                onClick={() => setPsychologyExpanded(!psychologyExpanded)}
              >
                <div className="sidebar-menu-options">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%"
                    }}
                  >
                    {psychologyExpanded && selectedOption !== "Home" ? (
                      <img src={IconBrainBlack} className="sidebar-icon" alt="plan psicología" />
                    ) : (
                      <img src={IconBrain} className="sidebar-icon" alt="plan psicología" />
                    )}

                    <p className={"hidden"} style={{ width: "60%" }}>
                      Bonos Psicología
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: psychologyExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility:
                          psychologyExpanded && selectedOption !== "Home" ? "visible" : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}

            {psychologyExpanded && (
              <div>
                <div
                  className={`option${selectedOption === "Schedule Session" ? " selected" : ""}`}
                  onClick={() => handleOptionClick("Schedule Session")}
                >
                  <div className="sidebar-menu-options">
                    <p style={{ paddingLeft: "50px" }}>Calendario y gestión de videollamada </p>
                  </div>
                </div>
                <div
                  className={`option${
                    selectedOption === "Contact Specialist Psychology" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Contact Specialist Psychology")}
                >
                  <div className="sidebar-menu-options">
                    <p style={{ paddingLeft: "50px" }}>Contacta con tu especialista</p>
                  </div>
                </div>
              </div>
            )}

            {psychoPlanSuscription && (
              <div
                className={`option option_header${
                  psychologySuscriptionExpanded && selectedOption !== "Home"
                    ? " selected"
                    : " white-option"
                }`}
                onClick={() => setPsychologySuscriptionExpanded(!psychologySuscriptionExpanded)}
              >
                <div className="sidebar-menu-options">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%"
                    }}
                  >
                    {psychologySuscriptionExpanded && selectedOption !== "Home" ? (
                      <img src={IconBrainBlack} className="sidebar-icon" alt="plan psicología" />
                    ) : (
                      <img src={IconBrain} className="sidebar-icon" alt="plan psicología" />
                    )}

                    <p className={"hidden psychology-subscription-pairs"} style={{ width: "90%" }}>
                      Suscripción Psicología
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: psychologySuscriptionExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility:
                          psychologySuscriptionExpanded && selectedOption !== "Home"
                            ? "visible"
                            : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {psychologySuscriptionExpanded && (
              <div>
                <div
                  className={`option${
                    selectedOption === "Schedule Session Suscription" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Schedule Session Suscription")}
                >
                  <div className="sidebar-menu-options psychology-subscription-options">
                    <p style={{ paddingLeft: "50px" }}>Calendario y gestión de videollamada </p>
                  </div>
                </div>
                <div
                  className={`option${
                    selectedOption === "Contact Specialist Psychology Suscription"
                      ? " selected"
                      : ""
                  }`}
                  onClick={() => handleOptionClick("Contact Specialist Psychology Suscription")}
                >
                  <div className="sidebar-menu-options  psychology-subscription-options">
                    <p style={{ paddingLeft: "50px" }}>Contacta con tu especialista</p>
                  </div>
                </div>
              </div>
            )}

            {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

            {psychoPlanDietPsi && (
              <div
                className={`option option_header${
                  psychologyDietPsiExpanded && selectedOption !== "Home"
                    ? " selected"
                    : " white-option"
                }`}
                onClick={() => setPsychologyDietPsiExpanded(!psychologyDietPsiExpanded)}
              >
                <div className="sidebar-menu-options">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%"
                    }}
                  >
                    {psychologyDietPsiExpanded && selectedOption !== "Home" ? (
                      <img src={IconBrainBlack} className="sidebar-icon" alt="plan psicología" />
                    ) : (
                      <img src={IconBrain} className="sidebar-icon" alt="plan psicología" />
                    )}

                    <p className={"hidden psychology-subscription-pairs"} style={{ width: "90%" }}>
                      V.Wellness psicología
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: psychologyDietPsiExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility:
                          psychologyDietPsiExpanded && selectedOption !== "Home"
                            ? "visible"
                            : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {psychologyDietPsiExpanded && (
              <div>
                <div
                  className={`option${
                    selectedOption === "Schedule Session DietMesPsi" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Schedule Session DietMesPsi")}
                >
                  <div className="sidebar-menu-options psychology-subscription-options">
                    <p style={{ paddingLeft: "50px" }}>Calendario y gestión de videollamada </p>
                  </div>
                </div>
                <div
                  className={`option${
                    selectedOption === "Contact Specialist Psychology DietMesPsi" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Contact Specialist Psychology DietMesPsi")}
                >
                  <div className="sidebar-menu-options  psychology-subscription-options">
                    <p style={{ paddingLeft: "50px" }}>Contacta con tu especialista</p>
                  </div>
                </div>
              </div>
            )}

            {/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */}

            {psychoPlanCouple && (
              <div
                className={`option option_header${
                  psychologyCoupleExpanded && selectedOption !== "Home"
                    ? " selected"
                    : " white-option"
                }`}
                onClick={() => setPsychologyCoupleExpanded(!psychologyCoupleExpanded)}
              >
                <div className="sidebar-menu-options sidebar-psi-couple">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "75%",
                      paddingBottom: "1rem"
                    }}
                  >
                    {psychologyCoupleExpanded && selectedOption !== "Home" ? (
                      <img
                        src={IconBrainBlack}
                        className="sidebar-icon"
                        alt="plan psicología pareja"
                      />
                    ) : (
                      <img src={IconBrain} className="sidebar-icon" alt="plan psicología pareja" />
                    )}

                    <p className={"hidden psychology-subscription-pairs"} style={{ width: "90%" }}>
                      Bonos Psicología pareja
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "25%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <img
                      src={IconChevron}
                      className={"chevron-img"}
                      style={{ rotate: psychologyCoupleExpanded ? "none" : "-90deg" }}
                    />
                    <img
                      src={optionMarker}
                      style={{
                        marginRight: 0,
                        visibility:
                          psychologyCoupleExpanded && selectedOption !== "Home"
                            ? "visible"
                            : "hidden"
                      }}
                      className={"option_marker"}
                    />
                  </div>
                </div>
              </div>
            )}
            {psychologyCoupleExpanded && (
              <div>
                <div
                  className={`option${
                    selectedOption === "Schedule Session Couple" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Schedule Session Couple")}
                >
                  <div className="sidebar-menu-options">
                    <p style={{ paddingLeft: "50px" }}>Calendario y gestión de videollamada </p>
                  </div>
                </div>
                <div
                  className={`option${
                    selectedOption === "Contact Specialist Psychology Couple" ? " selected" : ""
                  }`}
                  onClick={() => handleOptionClick("Contact Specialist Psychology Couple")}
                >
                  <div className="sidebar-menu-options">
                    <p style={{ paddingLeft: "50px" }}>Contacta con tu especialista</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="content-container">{renderContent()}</div>
      </div>
    </>
  )
}

export default Sidebar
